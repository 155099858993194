<template>
  <div class="arrlist-content">
    <div class="search-box">
      <el-form :inline="true" :model="searchFrom" ref="searchFrom" class="demo-form-inline" size="small">
        <el-form-item label-width="100px" label="支付状态" prop="paymentState">
          <el-select clearable v-model="searchFrom.paymentState" placeholder="请选择支付状态" style="width: 240px">
            <el-option v-for="item in $dict.paymentState" :key="item.code" :label="item.text"
              :value="item.code"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label-width="100px" label="订单编号" prop="serialNumber">
          <el-input style="width: 240px" clearable v-model="searchFrom.serialNumber" placeholder="请输入订单编号"></el-input>
        </el-form-item>
        <el-form-item label-width="100px" label="订单状态" prop="status">
          <el-select style="width: 240px" clearable v-model="searchFrom.status" placeholder="请选择订单状态">
            <el-option v-for="item in $dict.orderStatus" :key="item.code" :label="item.text"
              :value="item.code"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label-width="100px" label="收货人姓名" prop="consigneeName">
          <el-input style="width: 240px" clearable v-model="searchFrom.consigneeName" placeholder="请输入收货人姓名"></el-input>
        </el-form-item>
        <el-form-item label-width="100px" label="收货人电话" prop="consigneePhone">
          <el-input style="width: 240px" clearable v-model="searchFrom.consigneePhone" placeholder="请输入收货人电话"></el-input>
        </el-form-item>
        <el-form-item label-width="100px" label="商品名称" prop="goodsName">
          <el-input style="width: 240px" clearable v-model="searchFrom.goodsName" placeholder="请输入商品名称"></el-input>
        </el-form-item>
        <el-form-item label-width="100px" label="下单时间">
          <el-date-picker style="width: 240px" value-format="yyyy-MM-dd" @change="placeChange" v-model="placeReceiptTime"
            type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label-width="100px" label="确认收货时间">
          <el-date-picker style="width: 240px" value-format="yyyy-MM-dd" @change="pickerChange"
            v-model="confirmReceiptTime" type="daterange" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label-width="100px" label="发货时间">
          <el-date-picker style="width: 240px" value-format="yyyy-MM-dd" @change="confirmTimeChange" v-model="confirmTime"
            type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label-width="100px" label="配送方式" prop="wlStatus">
          <el-select style="width: 240px" clearable v-model="searchFrom.wlStatus" placeholder="请选择配送方式">
            <el-option label="自提" value="1"></el-option>
            <el-option label="物流" value="0"></el-option>
          </el-select>
        </el-form-item>
        <!-- 订单类型 -->
        <el-form-item label="订单类别" label-width="100px">
          <el-select style="width: 220px" clearable v-model="searchFrom.isPubGoods" placeholder="请选择订单类型">
            <el-option label="秒杀订单" value="3"></el-option>
            <el-option label="共享订单" value="1"></el-option>
            <el-option label="普通订单" value="0"></el-option>
            <el-option label="直播订单" value="5"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" style="margin-left: 100px" @click="search">搜索</el-button>
          <el-button @click="resetForm">重置</el-button>
          <el-button @click="exportEXCEL">导出EXCEL</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 表格 -->
    <div class="content-table">
      <div class="content-table customer-table">
        <el-table :data="tableData" style="width: 100%" :header-cell-style="{ 'text-align': 'center' }"
          :cell-style="{ 'text-align': 'center' }" border>
          <el-table-column type="index" label="#"> </el-table-column>
          <el-table-column prop="serialNumber" label="订单编号" width="200">
          </el-table-column>
          <el-table-column prop="userId" label="会员ID" width="auto">
          </el-table-column>
          <el-table-column label="商品名称" width="170">
            <template slot-scope="scope">
              <div v-for="(item, index) in scope.row.orderDetailList" :key="index" class="slb-cell" :class="index === scope.row.orderDetailList.length - 1
                ? 'border-none'
                : ''
                ">
                <span>{{ item.goodsName }}</span>
              </div>
              <span> </span>
            </template>
          </el-table-column>
          <el-table-column label="数量" width="120">
            <template slot-scope="scope">
              <div v-for="(item, index) in scope.row.orderDetailList" :key="index" class="slb-cell" :class="index === scope.row.orderDetailList.length - 1
                ? 'border-none'
                : ''
                ">
                <span>{{ item.quantity }}</span>
              </div>
              <span> </span>
            </template>
          </el-table-column>

          <el-table-column label="单价" width="300px">
            <template slot-scope="scope">
              <div v-for="(item, index) in scope.row.orderDetailList" :key="index" class="slb-cell" :class="index === scope.row.orderDetailList.length - 1
                ? 'border-none'
                : ''
                ">
                <div>￥{{ item.price }}</div>
                <div v-show="scope.row.isPubGoods == 3">
                  秒杀价：{{ item.slb }}
                </div>
              </div>
              <span> </span>
            </template>
          </el-table-column>
          <el-table-column label="小计" min-width="120">
            <template slot-scope="scope">
              <div v-for="(item, index) in scope.row.orderDetailList" :key="index" class="slb-cell" :class="index === scope.row.orderDetailList.length - 1
                ? 'border-none'
                : ''
                ">
                <span>￥{{
                  scope.row.isPubGoods == 3 ? item.slbAmount : item.amount
                }}</span>
              </div>
              <span> </span>
            </template>
          </el-table-column>

          <el-table-column label="配送方式" min-width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.wlStatus == 0">物流</span>
              <span v-else>自提</span>
            </template>
          </el-table-column>
          <el-table-column label="订单类型" min-width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.isPubGoods == 0">普通订单</span>
              <span v-else-if="scope.row.isPubGoods == 1">共享订单</span>
              <span v-else-if="scope.row.isPubGoods == 3">秒杀订单</span>
              <span v-else-if="scope.row.isPubGoods == 5">直播订单</span>
            </template>
          </el-table-column>
          <!--  -->
          <el-table-column prop="consigneeName" label="收货人姓名" min-width="120">
          </el-table-column>
          <el-table-column prop="consigneePhone" label="收货人手机号" min-width="120">
          </el-table-column>
          <el-table-column prop="address" label="收货地址" min-width="180">
            <template slot-scope="scope">
              <span :class="{ isUpdate: scope.row.isModifyAddress == 1 }">
                {{ scope.row.address }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="payTime" label="下单时间" min-width="140">
            <template slot-scope="scope">
              <span v-if="scope.row.payTime">{{ scope.row.payTime }}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column prop="total" label="订单总额" min-width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.total">￥{{ scope.row.total }}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>

          <el-table-column prop="totalGwj" label="乡村振兴购物券抵扣" min-width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.totalGwj">￥{{ scope.row.totalGwj }}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column prop="totalGzGwj" label="一县一品购物券抵扣" min-width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.totalGzGwj">￥{{ scope.row.totalGzGwj }}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column prop="couponsAmount" label="券抵扣金额" />

          <el-table-column prop="totalAmount" label="实付款" min-width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.totalAmount">￥{{ scope.row.totalAmount }}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <!--  -->
          <el-table-column prop="commission" label="平台扣点" min-width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.gwjCommission">￥{{ scope.row.gwjCommission }}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column prop="profit" label="商家盈利" min-width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.profit">￥{{ scope.row.profit }}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column prop="paymentState" label="支付状态" min-width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.orderState === 3">线下已付款</span>
              <span v-else-if="scope.row.orderState === 102">申请售后</span>

              <span v-else>{{
                $dict.paymentState | dictFormat(scope.row.paymentState)
              }}</span>
            </template>
            <!-- dictFormat -->
          </el-table-column>
          <el-table-column prop="paymentChannel" label="支付方式" min-width="120">
            <template slot-scope="scope">
              <span>{{
                $dict.paymentType | dictFormat(scope.row.paymentChannel)
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="buyerMessage" label="备注" min-width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.buyerMessage">{{
                scope.row.buyerMessage
              }}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column prop="shippingState" label="订单状态" min-width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.shippingState === 4 && scope.row.wlStatus == 1">
                待自提
              </span>
              <span v-else>{{
                $dict.orderStatus | dictFormat(scope.row.shippingState)
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="confirmReceiptTime" label="确认收货时间" min-width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.confirmReceiptTime">{{
                scope.row.confirmReceiptTime
              }}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column prop="shippingTime" label="发货时间" min-width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.shippingTime">{{
                scope.row.shippingTime
              }}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column prop="cashSettleTime" label="现金结款时间" min-width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.shopPaymentPayTime">{{
                scope.row.shopPaymentPayTime
              }}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column prop="gwjSettleTime" label="购物券结款时间" min-width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.gwjSettleTime &&
                  (scope.row.totalGwj || scope.row.totalGzGwj)
                  ">{{ scope.row.gwjSettleTime }}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="180" fixed="right">
            <template slot-scope="scope">
              <span v-if="scope.row.shippingState === 4 && scope.row.wlStatus == 1">自提商品请在手机端核销订单</span>
              <template v-else>
                <!-- currentRow -->
                <el-button type="text" size="small" @click="toOrderDetails(scope.row.orderId, scope.row)">查看
                </el-button>
                <el-button v-if="scope.row.shippingState >= 4" type="text" size="small"
                  @click="toLogisticsInfoOpen(scope.row)">
                  查看物流
                </el-button>
                <el-button type="text" v-if="scope.row.shippingState == 4" size="small"
                  @click="logisticsRevise(scope.row)">
                  修改物流
                </el-button>
                <el-button v-if="scope.row.shippingState === 3" type="text" size="small"
                  @click="deliver(scope.row)">发货</el-button>
                <el-button v-if="scope.row.shippingState === 3" type="text" size="small"
                  @click="editReceiver(scope.row)">修改收件人信息</el-button>
              </template>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="pagination-box">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum"
        :page-sizes="[10, 20, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>

    <!-- 发货 -->
    <el-dialog title="发货" :visible.sync="deliverDialogVisible" width="30%" @close="deliverDialogClose"
      v-if="deliverDialogVisible">
      <div>
        <el-form ref="deliverForm" :model="deliverForm" :rules="rules">
          <el-form-item>
            <p class="noticeTxt">完善物流公司信息后，请'添加物流单号'</p>
          </el-form-item>
          <el-form-item label="物流公司名称" prop="emsId" label-width="120px">
            <el-select v-model="deliverForm.emsId" placeholder="请选择" style="width: 280px" filterable allow-create>
              <el-option v-for="item in logisticsList" :key="item.id" :value="item.id" :label="item.name">
              </el-option>
            </el-select>
            <el-button @click="addDomain" type="primary" class="maginBtn">添加物流单号</el-button>
          </el-form-item>

          <el-form-item label-width="120px" v-for="(domain, index) in deliverForm.domains" :label="'物流单号' + (index + 1)"
            :key="domain.key" :prop="'domains.' + index + '.value'" :rules="{
              required: true,
              message: '物流单号不能为空',
              trigger: 'blur',
            }">
            <el-input v-model="domain.value" style="width: 60%"></el-input><el-button class="maginBtn"
              @click.prevent="removeDomain(domain)">删除</el-button>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deliverDialogClose">取 消</el-button>
        <el-button type="primary" @click="deliverSubmit">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 修改物流 -->
    <el-dialog title="修改物流" :visible.sync="logisticsReviseDialogVisible" width="30%">
      <div>
        <el-form ref="logisticsReviseForm" :model="logisticsReviseForm" :rules="rules"
          :before-close="logisticsReviseDialogClose">
          <el-form-item label="物流公司名称" prop="emsId" label-width="120px">
            <el-select v-model="logisticsReviseForm.emsId" placeholder="请选择" style="width: 280px">
              <el-option v-for="item in logisticsList" :key="item.id" :value="item.id" :label="item.name">
              </el-option>
            </el-select>
            <el-button @click="addEmsId" style="margin-left:10px" type="primary">添加物流单号</el-button>
          </el-form-item>

          <el-form-item :label="'物流单号' + (idx + 1)" prop="emsNum" label-width="120px"
            v-for="(itm, idx) in logisticsReviseForm.emsNum">
            <el-input type="text" style="width: 280px" onKeyUp="value=value.replace(/[\W]/g,'')" placeholder="请输入物流单号"
              v-model="itm.value" />
            <el-button @click="removeEmsNum(itm)">删除</el-button>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="logisticsReviseDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="logisticsReviseSubmit">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 侧边栏查看详情 -->
    <el-drawer title="查看详情" size="60%" :visible.sync="detailsDrawerVisible">
      <Details :orderData.sync="orderDetailData" :isSharedGoods.sync="isSharedGoods" @logisticsRevise="logisticsRevise"
        @logisticsOpen="logisticsOpen"></Details>
    </el-drawer>
    <!-- 物流详情 -->
    <logisticsInfo ref="logistics" :show="logisticsInfoShow" @close="logisticsInfoClose"
      @logisticsRevise="logisticsRevise" />

    <footer>
      <el-dialog :visible.sync="showRecieveInfo" title="修改收件信息" width="30%" @close="logisticsClose"
        v-if="showRecieveInfo">
        <el-form :model="recieveForm" :inline="true" label-width="130px" label-position="left">
          <el-form-item label="收件人：">
            <el-input v-model="recieveForm.consigneeName" class="inp">
            </el-input>
          </el-form-item>
          <el-form-item label="联系电话：">
            <el-input v-model="recieveForm.consigneePhone" class="inp" />
          </el-form-item>

          <el-form-item label="省/市/区：" label-width="110px">
            <div class="search_address">
              <el-select class="areaSelect" v-model="recieveForm.province" placeholder="请选择省" style="margin-right: 10px"
                @change="provinceChange" clearable>
                <el-option v-for="item in optionsProvince" :key="item.value" :label="item.text" :value="item.value" />
              </el-select>
              <el-select class="areaSelect" v-model="recieveForm.city" placeholder="请选择市" style="margin-right: 10px"
                @change="cityChange" :disabled="!recieveForm.province" clearable>
                <el-option v-for="item in optionsCity" :key="item.value" :label="item.text" :value="item.value" />
              </el-select>
              <el-select class="areaSelect" v-model="recieveForm.county" placeholder="请选择区" style="margin-right: 10px"
                @change="areaChange" :disabled="!recieveForm.city" clearable>
                <el-option v-for="item in optionsArea" :key="item.value" :label="item.text" :value="item.value" />
              </el-select>
            </div>
          </el-form-item>
          <el-form-item label="详细地址：">
            <el-input v-model="recieveForm.detailed" class="inp" />
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="logisticsClose">取消</el-button>
            <el-button type="primary" @click="sumitEditlogistics">确认</el-button>
          </span>
        </template>
      </el-dialog>
    </footer>
  </div>
</template>
<script>
// 查看详情
import Details from "@/views/manage/order/Details.vue";
import logisticsInfo from "@/views/manage/order/logisticsInfo.vue";
import { getCity, getProvince, getArea } from "@/utils/addressList";
export default {
  data() {
    return {
      showRecieveInfo: false, //收件信息弹窗
      recieveForm: {
        consigneeName: null, //收件人
        consigneePhone: null, //联系电话
        province: null, //省
        city: null, //市
        county: null, //区
        detailed: null, //详细地址
        orderId: null, //订单id
      },
      optionsProvince: getProvince(), //省
      optionsCity: [], //市
      optionsArea: [], //区
      provinceName: null, //省名称
      cityName: null, //市名称
      areaName: null, //区名称
      /* ---------- */
      logisticsReviseDialogVisible: false, //修改物流信息显示隐藏
      logisticsInfoShow: false, //物流详情显示隐藏
      detailsDrawerVisible: false, //详情显示隐藏
      currentRow: [], //当前操作row

      placeReceiptTime: null, //临时储存下单时间
      confirmReceiptTime: null, //临时储存确认收货时间
      confirmTime: null, //确认收货时间
      searchFrom: {
        // confirmReceiptTime: "", //确认收货时间
        isPubGoods: "", //订单类别 1.共享 0.普通
        wlStatus: null,
        serialNumber: "", //订单编号
        consigneeName: "", //收货人姓名
        consigneePhone: "", //收货人电话
        paymentState: "", //支付状态
        payBeginTime: "", //下单开始时间
        payEndTime: "", //下单结束时间
        goodsName: "", //商品名称
        status: "", //订单状态
        confirmBeginTime: "", //确认收货开始时间
        confirmEndTime: "", //确认收货结束时间
        shippingTimeStart: "", //发货收货开始时间
        shippingTimeEnd: "", //发货结束时间
      },
      logisticsList: [], //物流列表
      // 发货表单
      deliverForm: {
        emsId: null,
        emsNum: null,
        domains: [],
      },
      deliverDialogVisible: false, //发货弹窗
      // 校验规则
      rules: {
        emsId: [
          {
            required: true,
            message: "请选择物流公司",
            trigger: "change",
          },
        ],
      },
      // 修改物流信息表单
      logisticsReviseForm: {
        emsId: null,
        emsNum: [],
      },
      // 表格数据
      tableData: [],
      // 条数
      pageSize: 10,
      // 当前页
      pageNum: 1,
      // 总条数
      total: 0,
      currentOrderId: null, //选中订单ID
      orderDetailData: null, // 订单详情数据
      linkUrl: "https://www.kuaidi100.com/?from=openv", // 快递100链接
      isSharedGoods: null, // 订单类型 1.共享 0.普通
    };
  },
  components: {
    Details,
    logisticsInfo,
  },
  created() {
    this.getOrderList();
    this.getLogisticsList();
  },
  methods: {
    removeEmsNum(item) {
      let index = this.logisticsReviseForm.emsNum.indexOf(item);
      if (index != -1) {
        this.logisticsReviseForm.emsNum.splice(index, 1);
      }
    },
    addEmsId() {
      this.logisticsReviseForm.emsNum.push({
        value: "",
      });
    },
    // 初始化init  // 获取订单列表
    getOrderList() {
      let resData = {
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        ...this.searchFrom,
      };
      // console.log(resData);
      this.loading = true;
      this.$api.order
        .shopOrderList(this.$formatSubForm(resData))
        .then((res) => {
          this.loading = false;
          if (!res.success) {
            this.tableData = [];
            this.total = 0;
            return;
          }
          this.tableData = res.data.list;
          // console.log(res.data.list);
          this.total = parseInt(res.data.total);
        });
    },

    logisticsReviseDialogClose() {
      // 修改物流信息关闭
      this.logisticsReviseDialogVisible = false;
    },

    // 修改物流信息提交
    logisticsReviseSubmit() {
      let emsNum = this.logisticsReviseForm.emsNum.map(item => item.value).join(',')
      this.$api.order
        .updateOrderEms({
          emsId: this.logisticsReviseForm.emsId,
          emsNum,
          orderDetailId: this.currentRow.orderId,
        })
        .then((res) => {
          if (res.success) {
            this.$message.success(res.message);
            this.logisticsReviseDialogVisible = false;
            this.logisticsInfoShow = false;
            this.detailsDrawerVisible = false;
          } else {
            this.$message.error(res.message);
          }
          this.getOrderList();
          this.getLogisticsList();
        });
    },

    //-----------=== 查看物流详情
    toLogisticsInfoOpen(row) {
      //复制链接
      let item = row.shipNumber;
      // 模拟 输入框
      var cInput = document.createElement("input");
      cInput.value = item;
      document.body.appendChild(cInput);
      cInput.select(); // 选取文本框内容
      // 执行浏览器复制命令
      // 复制命令会将当前选中的内容复制到剪切板中（这里就是创建的input标签）
      // Input要在正常的编辑状态下原生复制方法才会生效
      document.execCommand("Copy");
      this.$message({
        type: "success",
        message: "复制成功",
      });
      // 复制成功后再将构造的标签 移除
      document.body.removeChild(cInput);
      // window.location.href = this.linkUrl;
      window.open(this.linkUrl, "_blank");
    },

    //-----------=== 子组件查看物流详情
    logisticsOpen() {
      this.$refs.logistics.queryWLInfo(this.currentRow.shipNumber);
    },

    //-----------=== 子组件修改物流
    logisticsRevise(row) {
      Object.assign(this.currentRow, row)
      this.logisticsReviseForm.emsId = parseInt(this.currentRow.emsId);
      this.logisticsReviseForm.emsNum = this.currentRow.shipNumber?.split(',').map(item => {
        return {
          value: item
        }
      });
      this.logisticsReviseDialogVisible = true;
    },

    //-----------=== 物流信息关闭
    logisticsInfoClose() {
      this.logisticsInfoShow = false;
    },

    //-----------=== 下单时间
    placeChange(value) {
      if (!value) {
        [this.searchFrom.payBeginTime, this.searchFrom.payEndTime] = [
          null,
          null,
        ];
        return;
      }
      this.searchFrom.payBeginTime = value[0];
      this.searchFrom.payEndTime = value[1];
    },

    //-----------=== 收货时间 confirmReceiptTime
    pickerChange(value) {
      if (!value) {
        [this.searchFrom.confirmBeginTime, this.searchFrom.confirmEndTime] = [
          null,
          null,
        ];
        return;
      }
      this.searchFrom.confirmBeginTime = value[0];
      this.searchFrom.confirmEndTime = value[1];
    },

    /**
     * @description:  发货时间 change
     * @Date: 2022-10-27 14:45:41
     * @author: cc
     */
    confirmTimeChange(value) {
      if (!value) {
        [this.searchFrom.shippingTimeStart, this.searchFrom.shippingTimeEnd] = [
          null,
          null,
        ];
        return;
      }
      [this.searchFrom.shippingTimeStart, this.searchFrom.shippingTimeEnd] =
        value;
    },

    // 导出EXcel
    exportEXCEL() {
      let isNull = Object.keys(this.searchFrom).every(
        (item) =>
          this.searchFrom[item] == null || String(this.searchFrom[item]) == ""
      );
      if (isNull) {
        this.$message({
          type: "warning",
          message: "请选择至少一种导出条件",
        });
        return;
      }
      this.$api.file
        .exportEXCEL(this.searchFrom, "/api/backstage/doExportOrderExcel")
        .then((res) => {
          // console.log(res);

          let reader = new FileReader();
          reader.readAsDataURL(res); //转换为base64，可以直接放入a标签href   接受的数据一定要配置responseType: blob
          reader.onload = function (e) {
            let a = document.createElement("a");
            a.download = "全部订单列表"; //此处填写文件地址
            a.href = e.target.result;
            document.body.appendChild(a); // 修复firefox中无法触发click
            a.click();
            document.body.removeChild(a);
          };
        });
    },

    // 获取物流公司列表
    getLogisticsList() {
      this.$api.order.slbemsEmsList().then((res) => {
        // console.log(res);
        if (res.success) {
          this.logisticsList = res.data ? res.data : [];
        }
      });
    },

    /**
     * 编辑收件人信息
     * @Date: 2022-10-06 09:12:52
     * @author: cc
     */

    editReceiver(row) {
      this.$api.order
        .orderDetails({
          id: row.orderId,
          isSharedGoods: row.isPubGoods,
        })
        .then((res) => {
          if (!res.data) {
            this.$message.warning(res.message);
            return;
          }
          // console.log(res);
          // 收件信息编辑-数据渲染
          this.recieveForm.consigneeName = res.data.consigneeName;
          this.recieveForm.consigneePhone = res.data.consigneePhone;
          this.recieveForm.province = res.data.province;
          this.recieveForm.city = res.data.city;
          this.recieveForm.county = res.data.county;
          this.recieveForm.detailed = res.data.detailed;
          this.recieveForm.orderId = row.orderId;

          this.provinceName = res.data.province;
          this.cityName = res.data.city;
          this.areaName = res.data.county;
          //  显示物流详情弹层
          this.showRecieveInfo = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    /* 收件地址编辑弹层关闭 */
    logisticsClose() {
      this.recieveForm = {};
      this.showRecieveInfo = false;
    },

    /* 修改收件信息 */
    sumitEditlogistics() {
      this.$api.order
        .updateReceiveAddress({
          ...this.recieveForm,
          province: this.provinceName,
          city: this.cityName,
          county: this.areaName,
        })
        .then((res) => {
          this.$message.success(res.message);
          this.showRecieveInfo = false;
          this.getOrderList();
        })
        .catch((err) => {
          this.showRecieveInfo = false;
        });
    },
    // 新曾物流单号
    addDomain() {
      this.deliverForm.domains.push({
        value: "",
        key: Date.now(),
      });
    },

    removeDomain(item) {
      var index = this.deliverForm.domains.indexOf(item);
      if (index !== -1) {
        this.deliverForm.domains.splice(index, 1);
      }
    },

    provinceChange(val) {
      this.recieveForm.city = "";
      this.optionsCity = getCity(val);
      let keywords = "";

      for (let i of this.optionsProvince) {
        if (val == i.value) {
          keywords = i.text;
        }
      }
      this.provinceName = keywords;
    },

    cityChange(val) {
      this.recieveForm.county = "";
      this.optionsArea = getArea(val);
      let keywords = "";
      for (let i of this.optionsCity) {
        if (val == i.value) {
          keywords = i.text;
        }
      }
      this.cityName = keywords;
    },

    areaChange(val) {
      let keywords = "";
      for (let i of this.optionsArea) {
        if (val == i.value) {
          keywords = i.text;
        }
      }
      this.areaName = keywords;
    },

    // 查看
    toOrderDetails(id, row) {
      // 保存当前操作列表对应行
      this.currentRow = row;
      const isSharedGoods = row.isPubGoods;
      this.isSharedGoods = row.isPubGoods;
      // console.log(id);
      // 查看 详情
      this.$api.order
        .orderDetails({
          id: id,
          isSharedGoods: isSharedGoods,
        })
        .then((res) => {
          const { data, success, message } = res;
          // data
          if (!success) {
            this.$message.error(message);
            return;
          }
          this.orderDetailData = data;
          // console.log(res);
          this.detailsDrawerVisible = true;
        });
    },

    // 搜索重置
    resetForm() {
      // this.searchFrom.payBeginTime = null;
      // this.searchFrom.payEndTime = null;
      // this.searchFrom.confirmBeginTime = null;
      // this.searchFrom.confirmEndTime = null;
      // this.searchFrom.isPubGoods = null;
      this.$clearForm(this.searchFrom);
      this.confirmTime = null;
      this.placeReceiptTime = null;
      this.confirmReceiptTime = null;
      this.$refs["searchFrom"].resetFields();
      this.getOrderList();
    },

    // 搜索
    search() {
      this.getOrderList();
    },

    // 弹窗显示 发货
    deliver(item) {
      this.currentOrderId = item.orderId;
      this.deliverDialogVisible = true;
    },

    // 发货提交
    deliverSubmit() {
      this.$refs["deliverForm"].validate(async (valid) => {
        if (valid) {
          let emsNumArrat = [];
          this.deliverForm.domains.forEach((item) => {
            emsNumArrat.push(item.value);
          });
          let resData = {
            emsId: this.deliverForm.emsId,
            emsNum: emsNumArrat.toString(),
            orderId: this.currentOrderId,
          };
          if (!resData.emsNum) {
            this.$message({
              type: "info",
              message: "请添加物流单号",
            });
            return;
          }
          await this.$api.order.confirmShopOrder(resData).then((res) => {
            if (res.success) {
              this.getOrderList();

              this.$message({
                type: "success",
                message: res.message,
              });
            } else {
              this.$message.error(res.message);
            }
            this.deliverDialogClose();
          });
        }
      });
    },

    // 发货弹窗关闭
    deliverDialogClose() {
      // this.$refs["deliverForm"].resetFields();
      Object.keys(this.deliverForm).forEach((item) => {
        this.deliverForm[item] = null;
      });
      this.deliverForm.domains = [];
      this.deliverDialogVisible = false;
    },

    handleSizeChange(val) {
      // 每页条数
      this.pageSize = val;
      this.getOrderList();
    },
    handleCurrentChange(val) {
      // 当前页面
      this.pageNum = val;
      this.getOrderList();
    },
  },
};
</script>

<style lang="scss" scoped>
.isUpdate {
  color: red;
}

.arrlist-content {
  .pagination-box {
    padding: 8px 0;
    display: flex;
    justify-content: flex-end;
  }
}

.maginBtn {
  margin-left: 20px;
}

.noticeTxt {
  color: #ff5000;
}

.slb-cell {
  padding: 0 8px;
  line-height: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: 1px solid #dddddd;

  &.border-none {
    border: none;
  }
}

.inp {
  width: 100%;
}
</style>
<style lang="scss">
.arrlist-content {
  footer {
    .el-form-item .el-form-item__content {
      width: 100%;
    }

    .el-form-item--small.el-form-item {
      display: flex;
      justify-content: space-between;
    }
  }

  .search_address .el-select {
    width: 25%;
  }

  .search_address {
    margin-left: 3%;
  }
}
</style>
