<template>
  <el-dialog
    title="售后详情"
    :visible.sync="dialogTableVisible"
    width="60%"
    
  >
    <el-row class="info-box">
      <!-- 售后信息 -->
      <el-row class="aftrt-info">
        <el-row>
          <el-col class="state">
            <h1>{{ $dict.afterSales[details.status] }}</h1>
            <p v-if="details.status == 0 || details.status == 5">
              <span class="time">{{ days < 0 ? 0 : days }}</span> 天
              <span class="time">{{ hour < 0 ? 0 : hour }}</span> 时
              <span class="time">{{ minute < 0 ? 0 : minute }}</span>
              <span>{{
                `分内未审核，系统自动同意${
                  (details.status == 0&&details.afterSaleType!=1) || (details.status == 3&&details.afterSaleType!=1 )? "退货" : "退款"
                }`
              }}</span>
            </p>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="9">
            <div class="titleBox">
              <p class="titleIcon"></p>
              <p>售后信息</p>
            </div>
            <el-col class="info">
              <el-col class="info-row"
                ><span class="infoTitle">售后编号</span
                ><span>{{ details.afterSaleNo }}</span></el-col
              >
              <el-col class="info-row"
                ><span class="infoTitle">售后类型</span
                ><span>
                  {{
                    details.afterSaleType == 0
                      ? "退货退款"
                      : details.afterSaleType == 1
                      ? "仅退款"
                      : "--"
                  }}</span
                ></el-col
              >
              <el-col class="info-row"
                ><span class="infoTitle">售后原因</span
                ><span>{{ details.afterSaleReasons }}</span></el-col
              >
              <el-col class="info-row"
                ><span class="infoTitle">申请时间</span
                ><span>{{ details.createdTime }}</span></el-col
              >
              <el-col class="info-row"
                ><span class="infoTitle">申请退款金额</span
                ><span>{{ details.applyRefundAmount }}</span></el-col
              >
              <el-col class="info-row"
                ><span class="infoTitle">实际退款金额</span
                ><span>{{ details.actualRefundAmount }}</span></el-col
              >
              <el-col class="info-row" v-if="image.length > 0"
                ><span class="infoTitle">相关凭证</span>
                <el-image
                  v-for="(item, index) in image"
                  :preview-src-list="image"
                  :key="index"
                  :src="item"
                  class="slotImg"
                />
              </el-col>
              <el-col class="info-row"
                ><span class="infoTitle">补充描述</span
                ><span>{{ details.afterSaleDetails }}</span></el-col
              >
            </el-col>
          </el-col>
          <el-col :span="9">
            <div class="titleBox">
              <p class="titleIcon"></p>
              <p>订单信息</p>
            </div>
            <el-col class="info">
              <el-col class="info-row"
                ><span class="infoTitle">订单编号</span
                ><span>{{ details.orderNo }}</span></el-col
              >
              <el-col class="info-row"
                ><span class="infoTitle">订单类型</span
                ><span>{{
                  details.type == 0
                    ? "部分退款"
                    : details.type == 1
                    ? "整单退款"
                    : "--"
                }}</span></el-col
              >
              <el-col class="info-row"
                ><span class="infoTitle">支付方式</span
                ><span>{{
                  details.payChannel == 1
                    ? "微信"
                    : details.payChannel == 2
                    ? "支付宝"
                    : "--"
                }}</span></el-col
              >
              <el-col class="info-row"
                ><span class="infoTitle">下单时间</span
                ><span>{{ details.payTime }}</span></el-col
              >
            </el-col>
          </el-col>
          <el-col :span="6">
            <div class="titleBox">
              <p class="titleIcon"></p>
              <p>买家信息</p>
            </div>
            <el-col class="info">
              <el-col class="info-row"
                ><span class="infoTitle">买家昵称</span
                ><span>{{ details.userName }}</span></el-col
              >
              <el-col class="info-row"
                ><span class="infoTitle">用户ID</span
                ><span>{{ details.userId }}</span></el-col
              >

              <div class="titleBox">
                <p class="titleIcon"></p>
                <p>卖家信息</p>
              </div>

              <el-col class="info">
                <el-col class="info-row"
                  ><span class="infoTitle">卖家名称</span
                  ><span>{{ details.shopName }}</span></el-col
                >
                <el-col class="info-row"
                  ><span class="infoTitle">店铺ID</span
                  ><span>{{ details.shopId }}</span></el-col
                >
              </el-col>
            </el-col>
          </el-col>
        </el-row>
      </el-row>

      <el-row class="aftrt-info">
        <!-- 退货物流 -->
        <el-row :gutter="10" v-show="details.deliveryNo">
          <el-col :span="14">
            <div class="titleBox">
              <p class="titleIcon"></p>
              <p>退货物流</p>
            </div>
            <el-col class="info">
              <el-col class="info-row"
                ><span class="infoTitle">物流公司</span
                ><span>{{ details.deliveryName }}</span></el-col
              >
              <el-col class="info-row"
                ><span class="infoTitle">物流单号</span
                ><span>{{ details.deliveryNo }}</span></el-col
              >
              <el-col class="info-row"
                ><span class="infoTitle">退货时间</span
                ><span>{{ details.deliveryTime }}</span></el-col
              >
            </el-col>
          </el-col>
          <el-col :span="10">
            <el-button type="text" @click="goView">查看物流信息</el-button>
          </el-col>
        </el-row>
      </el-row>

      <el-row class="aftrt-info">
        <!-- 退货物流 -->
        <el-row :gutter="10">
          <el-col>
            <div class="titleBox">
              <p class="titleIcon"></p>
              <p>商品信息</p>
            </div>
            <el-col class="info">
              <el-table border :data="tableList">
                <el-table-column label="商品" width="400">
                  <template slot-scope="scope">
                    <div class="slotBox">
                      <div class="slotLeft">
                        <el-image
                          class="slotImg"
                          :src="scope.row.productImageUrl"
                        ></el-image>
                      </div>
                      <div>
                        <p>商品名称：{{ scope.row.productName }}</p>
                        <p>商品规格：{{ scope.row.productDetailGroupName }}</p>
                        <p>商品ID：{{ scope.row.id }}</p>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="单价"
                  prop="price"
                  align="center"
                ></el-table-column>
                <el-table-column
                  label="数量"
                  prop="quantity"
                  align="center"
                ></el-table-column>
                <el-table-column label="小计" prop="quantity" align="center">
                  <template slot-scope="scope">
                    <span
                      >￥{{
                        (scope.row.price * scope.row.quantity).toFixed(2)
                      }}</span
                    >
                  </template>
                </el-table-column>
              </el-table>
              <p class="amount">合计：￥{{ amount }}</p>
            </el-col>
          </el-col>
        </el-row>
      </el-row>

      <el-row class="aftrt-info">
        <!-- 退货物流 -->
        <div class="titleBox">
          <p class="titleIcon"></p>
          <p>售后记录</p>
        </div>
        <el-row :gutter="10">
          <el-col v-for="item in logs" :key="item.id">
            <el-row>
              <span class="shopType">{{ $dict.roleType[item.roleType] }}</span
              ><span class="afterType">{{
                $dict.afterSales[item.events]
              }}</span>
            </el-row>

            <span
              class="afterInfo"
              :span="4"
              v-for="(value, key) in formatArr(item)"
              :key="key"
            >
              <span class="infoTitle">{{ value }}</span>
            </span>
          </el-col>

          <el-col>
            <el-row>
              <span class="shopType">买家</span
              ><span class="afterType">申请售后</span>
            </el-row>

            <el-col class="info" :span="12">
              <el-col class="info-row"
                ><span class="infoTitle">售后类型</span
                ><span>{{
                  details.afterSaleType == 0
                    ? "退货退款"
                    : details.afterSaleType == 1
                    ? "仅退款"
                    : "--"
                }}</span></el-col
              >
              <el-col class="info-row"
                ><span class="infoTitle">申请时间</span
                ><span>{{ details.createdTime }}</span></el-col
              >
              <el-col class="info-row" v-if="image"
                ><span class="infoTitle">相关凭证</span>
                <el-image
                  v-for="(item, index) in image"
                  :preview-src-list="image"
                  :key="index"
                  :src="item"
                  class="slotImg"
                />
              </el-col>
            </el-col>
            <el-col class="info" :span="12">
              <el-col class="info-row"
                ><span class="infoTitle">售后原因</span
                ><span>{{ details.afterSaleReasons }}</span></el-col
              >
              <el-col class="info-row"
                ><span class="infoTitle">申请退款金额</span
                ><span>{{ details.applyRefundAmount }}</span></el-col
              >
              <el-col class="info-row"
                ><span class="infoTitle">补充描述</span
                ><span>
                  {{ details.afterSaleDetails }}
                </span></el-col
              >
            </el-col>
          </el-col>
        </el-row>
      </el-row>
    </el-row>
  </el-dialog>
</template>
<script>
import { getDetails } from "@/assets/api/order";
export default {
  props: {
    details: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      dialogTableVisible: false, // 售后详情弹框
      tableList: [], // 售后详情弹框
      logs: [], // 售后详情弹框
      hour: "", // 售后详情弹框
      minute: "", // 售后详情弹框
      days: "", // 售后详情弹框
      amount: 0, // 售后详情弹框
      logsDetiles: [], // 售后详情弹框
      image: [], // 详情
    };
  },

  watch: {
    // 售后详情弹框
    dialogTableVisible(newVal) {
      if (newVal) {
        this.getShopInfo();
        this.getTime();
      } else {
        this.amount = 0;
      }
    },
  },

  methods: {
    getShopInfo() {
      this.image = this.details.afterSaleCredentials.split(",");
      // 售后详情弹框
      getDetails({
        id: this.details.id,
        orderNo: this.details.orderNo,
      }).then((res) => {
        if (res.code == 200) {
          this.tableList = res.data.detailDto;
          this.logs = res.data.logs;
          this.tableList.forEach((item) => {
            this.amount += item.price * item.quantity;
          });
          if (this.amount) {
            this.amount = this.amount.toFixed(2);
          }
        } else {
          this.$message.error(res.message);
        }
      });
    },

    formatArr(ItemArr) {
      // 售后详情弹框
      let formatArr = [];
      formatArr = Object.entries(JSON.parse(ItemArr.content))
        .map(([key, value]) => `${key}: ${value}`)
        .join(", ");
      return formatArr.split(",");
    },

    getTime() {
      // 获取时间格式化
      let time = "";
      let fiveDaysLater = "";
      const statusMap = {
        0: 5,
        1: 5,
        3: 5,
        7: 5,
        5: 10,
        8: 10,
      };

      if (statusMap[this.details.status]) {
        time =
          this.details[
            this.details.status == 0 || this.details.status == 1
              ? "createdTime"
              : this.details.status == 3
              ? "refundGoodsPlatformInvolvementTime"
              : this.details.status == 5
              ? "deliveryTime"
              : this.details.status == 7
              ? "refundExamineTime"
              : this.details.status == 8
              ? "refundPlatformInvolvementTime"
              : ""
          ];
        if (!time) {
          time = this.details.createdTime;
        }

        fiveDaysLater =
          new Date(time).getTime() +
          statusMap[this.details.status] * 24 * 60 * 60 * 1000;
      }
      // 当前时间
      const currentTime = new Date();
      // 计算时间差
      const timeDiff = fiveDaysLater - currentTime;
      fiveDaysLater;
      const date = new Date(timeDiff);
      this.days = Math.floor(date / (1000 * 60 * 60 * 24));
      this.hour = Math.floor((date / (1000 * 60 * 60)) % 24);
      this.minute = Math.floor((date / (1000 * 60)) % 60);
    },



    goView() {
      // 查看物流信息
      window.open("https://www.kuaidi100.com/");
    },
  },
};
</script>
<style scoepd lang="scss">
.el-dialog__header {
  background-color: #f1f1f1;
}

.info-box {
  height: 70vh;
  overflow-y: auto;

  background-color: #f1f1f1;
  .aftrt-info {
    background-color: #fff;
    padding: 20px;
    margin-bottom: 10px;
  }
  .state {
    padding: 0 40px 40px;
    .time {
      color: red;
      font-size: 20px;
      font-weight: bold;
    }
  }
  .afterType {
    font-size: 16px;
    margin-left: 20px;
    font-weight: bold;
  }
  .info {
    margin: 30px 0;
    .infoTitle {
      width: 100px;
      text-align: right;
      color: #999999;
      display: inline-block;
      margin-right: 10px;
    }
    &-row {
      margin-bottom: 20px;
      display: flex;
      align-items: flex-start;
    }

    .slotBox {
      display: flex;
      .slotLeft {
        margin: 30px;
      }
    }
    .amount {
      text-align: right;
    }
  }
  .slotImg {
    width: 80px;
    height: 80px;
    margin-right: 10px;
  }
  .shopType {
    font-weight: bold;
    color: #000;
  }
  .afterInfo {
    display: inline-block;
    margin: 30px;
  }
  .record {
    padding-bottom: 20px;
  }

  .titleIcon {
    width: 6px;
    height: 12px;
    background-color: #409eff;
    margin-right: 4px;
  }
  .titleBox {
    display: flex;
    align-items: center;
    padding: 4px;
  }
}
</style>
