<template>
  <el-card>
    <el-collapse v-model="collapseNames" class="returnGoods">
      <el-collapse-item name="1">
        <template slot="title">
          <div class="returnGoods-header">
            <div>
              <i class="el-icon-search"></i>
              <span>筛选搜索</span>
            </div>
            <div>
              <el-button
                size="small"
                type="primary"
                @click.stop="handleSearchList"
                >查询搜索
              </el-button>
              <el-button size="small" @click.stop="handleResetSearch"
                >重置</el-button
              >
              <el-button size="small" type="warning" @click="downloadExcel"
                >导出Excel表格</el-button
              >
            </div>
          </div>
        </template>
        <el-form :inline="true" label-width="100px">
          <el-row>
            <el-form-item label="售后编号">
              <el-input
                placeholder="售后编号"
                v-model="dataForm.afterSaleNo"
              ></el-input>
            </el-form-item>
            <el-form-item label="订单编号">
              <el-input
                placeholder="订单编号"
                v-model="dataForm.orderNo"
              ></el-input>
            </el-form-item>
            <el-form-item label="售后状态">
              <el-select placeholder="请选择售后状态" v-model="dataForm.status">
                <el-option
                  v-for="item in $dict.afterStatus"
                  :label="item.label"
                  :value="item.value"
                  :key="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-row>
          <el-form-item label="买家">
            <el-input placeholder="买家ID" v-model="dataForm.userId"></el-input>
          </el-form-item>

          <el-form-item label="申请时间">
            <el-date-picker
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              v-model="time"
              value-format="yyyy-MM-dd"
            />
          </el-form-item>
        </el-form>
        <div class="myCollapse-close" @click="collapseNames = ''">
          <i class="el-icon-caret-top" />
          收起筛选项
        </div>
      </el-collapse-item>
    </el-collapse>
    <el-table :data="tableList" border>
      <el-table-column
        label="售后编号"
        align="center"
        prop="afterSaleNo"
        fixed
        width="200"
      />
      <el-table-column
        label="订单编号"
        align="center"
        prop="orderNo"
        fixed
        width="200"
      />
      <el-table-column label="订单类型" align="center" prop="orderNumber">
        <template slot-scope="scope">
          <span>{{
            scope.row.type == 0
              ? "部分退款"
              : scope.row.type == 1
              ? "整单退款"
              : "--"
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="订单总额" align="center" prop="totalAmount" />
      <el-table-column label="支付方式" align="center" prop="orderNumber">
        <template slot-scope="scope">
          <span>
            {{ getArrVal($dict.paymentChannel, scope.row.payChannel)["label"] }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="售后类型" align="center" prop="orderNumber">
        <template slot-scope="scope">
          <span
            >{{
              scope.row.afterSaleType == 0
                ? "退货退款"
                : scope.row.afterSaleType == 1
                ? "仅退款"
                : "--"
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        label="售后原因"
        align="center"
        prop="afterSaleReasons"
      />

      <el-table-column
        label="申请退款金额"
        align="center"
        prop="applyRefundAmount"
        width="120"
      />
      <el-table-column
        label="实际退款金额"
        align="center"
        prop="actualRefundAmount"
        width="120"
      />
      <el-table-column label="售后状态" align="center" prop="orderNumber">
        <template slot-scope="scope">
          {{ $dict.afterSales[scope.row.status] }}
        </template>
      </el-table-column>
      <el-table-column
        label="申请时间"
        align="center"
        prop="createdTime"
        width="200"
      />
      <el-table-column
        label="退款时间"
        align="center"
        prop="refundTime"
        width="200"
      />
      <el-table-column label="买家ID" align="center" prop="userId" />

      <el-table-column
        label="操作"
        align="center"
        prop="orderNumber"
        fixed="right"
        width="250"
      >
        <template slot-scope="scope">
          <div class="operate">
            <el-button
              type="primary"
              size="mini"
              @click="view(scope.row)"
              class="btn"
              >查看</el-button
            >
            <el-popconfirm
              confirm-button-text="同意退货"
              cancel-button-text="拒绝退货"
              icon="el-icon-info"
              icon-color="red"
              title="是否通过审核"
              @confirm="agree(scope.row, 'returnGoods')"
              @cancel="refuse(scope.row, 'returnGoods')"
              cancel-button-type="danger"
              confirm-button-type="success"
            >
              <el-button
                slot="reference"
                size="mini"
                type="warning"
                v-show="scope.row.status == 0 && scope.row.afterSaleType != 1"
                >审核退货</el-button
              >
            </el-popconfirm>
            <el-popconfirm
              confirm-button-text="同意退款"
              cancel-button-text="拒绝退款"
              icon="el-icon-info"
              icon-color="red"
              title="是否通过审核"
              @confirm="agree(scope.row, 'refund')"
              @cancel="refuse(scope.row, 'refund')"
              cancel-button-type="danger"
              confirm-button-type="success"
            >
              <el-button
                slot="reference"
                size="mini"
                type="danger"
                v-show="
                  scope.row.status == 4 ||
                  scope.row.status == 5 ||
                  scope.row.afterSaleType == 1&&scope.row.status == 0
                "
                >审核退款</el-button
              >
            </el-popconfirm>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-container">
      <el-pagination
        :current-page.sync="dataForm.pageNo"
        :page-size="dataForm.pageSize"
        :page-sizes="[10, 20, 30]"
        :total="total"
        background
        layout="total, sizes,prev, pager, next,jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <!-- 售后详情 -->
    <AfterInfo ref="infoDialog" :details="objeDetails" />
    <AfterDialog ref="refunds" :details="parameter" @change="getList" />
    <el-dialog
      :title="text"
      :visible.sync="refuseDailog"
      width="30%"
      :before-close="handleClose"
    >
      <el-form>
        <el-form-item label="拒绝原因">
          <el-input
            type="textarea"
            v-model="describe"
            placeholder="请输入拒绝原因"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="refuseDailog = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>
<script>
import AfterInfo from "./components/AfterInfo";
import AfterDialog from "./components/AfterDialog";

import {
  afterSalePage,
  rejectReturnGoods,
  rejectReturn,
} from "@/assets/api/order";
import dict from "@/assets/dict";
export default {
  components: {
    AfterInfo,
    AfterDialog,
  },

  data() {
    return {
      collapseNames: null, // 折叠面板
      tableList: [], // 列表数据
      dataForm: {
        afterSaleNo: null, // 售后编号
        endTime: null, // 结束时间
        orderNo: null, // 订单编号
        pageNo: 1, // 页码
        pageSize: 10, // 页数
        shopId: null, // 店铺id
        shopName: null, // 店铺名称
        startTime: null, // 开始时间
        status: null, // 状态
        userId: null, // 用户id
      },
      time: [], // 时间
      total: 0, // 总条数
      objeDetails: {}, // 售后详情
      text: "", // 弹窗标题
      refuseDailog: false, // 弹窗
      describe: "", // 拒绝理由
      parameter: {}, // 退款详情
      switchApi: null, // 接口
      parameter: {}, // 退款详情
    };
  },

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      // 售后列表
      this.dataForm.startTime = this.time[0] || "";
      this.dataForm.endTime = this.time[1] || "";
      const shopId = JSON.parse(localStorage.getItem("store")).user.shopId;
      afterSalePage({
        ...this.dataForm,
        shopId,
      }).then((res) => {
        const { data, code, message } = res;
        if (code == 200) {
          this.tableList = data.records;
          this.total = Number(data.total);
        } else {
          this.$message.error(message);
        }
      });
    },

    getExportData() {
      // 导出数据
      const shopId = JSON.parse(localStorage.getItem("store")).user.shopId;
      this.dataForm.pageSize = this.total;
      const { afterSales, shopType } = dict;
      afterSalePage({ ...this.dataForm, shopId }).then((res) => {
        const { data, code } = res;
        if (code == 200) {
          this.dataForm.pageSize = 10;
          const tableList = data.records;
          tableList.forEach((item) => {
            item.type == 0
              ? (item.type = "部分退款")
              : (item.type = "整单退款");
            item.status = afterSales[item.status];
            item.payChannel = this.getArrVal(
              dict.paymentChannel,
              item.payChannel
            )["label"];
            item.afterSaleType == 0
              ? (item.afterSaleType = "退货退款")
              : item.afterSaleType == 1
              ? (item.afterSaleType = "仅退款")
              : (item.afterSaleType = "--");
            item.shopType = shopType[item.shopType];
          });
          const { export_json_to_excel } = require("@/vendor/Export2Excel");
          const tHeader = [
            "售后编号",
            "订单编号",
            "订单类型",
            "订单总额",
            "支付方式",
            "售后类型",
            "售后原因",
            "申请退款金额",
            "实际退款金额",
            "售后状态",
            "申请时间",
            "退款时间",
            "买家ID",
            "店铺ID",
          ];
          const filterVal = [
            "afterSaleNo",
            "orderNo",
            "type",
            "totalAmount",
            "payChannel",
            "afterSaleType",
            "afterSaleReasons",
            "applyRefundAmount",
            "actualRefundAmount",
            "status",
            "createdTime",
            "refundTime",
            "userId",
            "shopId",
          ];
          const list = tableList;
          const parameter = this.formatJson(filterVal, list);
          export_json_to_excel(tHeader, parameter, "退货退款列表");
        } else {
          this.$message.error("导出失败");
        }
      });
    },

    downloadExcel() {
      // 导出
      this.$confirm("确定要导出表格数据吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.getExportData();
        })
        .catch(() => {
          this.$message({ type: "info", message: "已取消导出" });
        });
    },

    agree(val, type) {
      // 同意审核
      const status = type == "refund" ? 9 : 2; // 退款同意 退货同意;
      this.$refs.refunds.dialogVisible = true;
      this.parameter = {
        applyRefundAmount: val.applyRefundAmount,
        id: val.id,
        status: status,
        type,
        actualRefundAmount: val.actualRefundAmount,
      };
    },

    refuse(val, type) {
      // 拒绝审核
      const status = type == "refund" ? 7 : 1; // 退款拒绝 退货拒绝
      this.refuseDailog = true;
      this.text = type == "returnGoods" ? "拒绝退货" : "拒绝退款";
      this.switchApi = type == "returnGoods" ? rejectReturnGoods : rejectReturn;
      this.parameter = {
        applyRefundAmount: val.applyRefundAmount,
        id: val.id,
        status: status,
        actualRefundAmount: val.actualRefundAmount,
      };
    },

    submit() {
      // 提交
      this.switchApi({
        ...this.parameter,
        describe: this.describe,
      }).then((res) => {
        const { code, message } = res;
        this.refuseDailog = false;
        if (code == 200) {
          this.$message.success(message);
          this.describe = "";
          this.getList();
        } else {
          this.$message.error(message);
        }
      });
    },

    handleSizeChange(val) {
      // 条数改变的回调
      this.dataForm.pageSize = val;
      this.getList();
    },

    handleCurrentChange(val) {
      // 当前页改变的回调
      this.dataForm.pageNo = val;
      this.getList();
    },

    view(value) {
      // 查看
      this.$refs.infoDialog.dialogTableVisible = true;
      this.objeDetails = value;
    },

    handleSearchList() {
      // 搜索
      this.getList();
    },
    handleResetSearch() {
      // 重置
      this.dataForm = {
        afterSaleNo: null,
        endTime: null,
        orderNo: null,
        pageNo: 1,
        pageSize: 10,
        shopId: null,
        shopName: null,
        startTime: null,
        status: null,
        userId: null,
      };
      this.time = [];
      this.getList();
    },

    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
  },
};
</script>
<style lang="scss" scoped>
.returnGoods {
  width: 100%;
  &-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}
.btn {
  margin: 0 10px;
}
</style>
