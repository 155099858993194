<template>
  <div class="content">
    <div class="search-box">
      <el-form :inline="true" :model="searchFrom" ref="searchFrom" size="small">
        <el-form-item label="订单编号" label-width="100px" prop="serialNumber">
          <el-input
            style="width: 240px"
            clearable
            v-model="searchFrom.serialNumber"
            placeholder="请输入订单编号"
          ></el-input>
        </el-form-item>
        <el-form-item label="商品名称" label-width="100px" prop="goodsName">
          <el-input
            style="width: 240px"
            clearable
            v-model="searchFrom.goodsName"
            placeholder="请输入商品名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="状态" label-width="100px" prop="refundState">
          <el-select
            clearable
            v-model="searchFrom.refundState"
            placeholder="请选择状态"
            style="width: 240px"
          >
            <el-option
              v-for="item in $dict.refundState"
              :key="item.code"
              :label="item.text"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="联系电话" label-width="100px" prop="phone">
          <el-input
            style="width: 240px"
            clearable
            v-model="searchFrom.phone"
            placeholder="请输入联系电话"
          ></el-input>
        </el-form-item>

        <el-form-item label-width="100px" label="订单类型" prop="wlStatus">
          <el-select
            style="width: 240px"
            clearable
            v-model="searchFrom.wlStatus"
            placeholder="请选择订单状态"
          >
            <el-option label="自提订单" value="1"></el-option>
            <el-option label="物流订单" value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label-width="100px" label="订单类别" prop="wlStatus">
          <el-select
            style="width: 240px"
            clearable
            v-model="searchFrom.isPubGoods"
            placeholder="请选择订单类别"
          >
            <el-option label="普通订单" value="0"></el-option>
            <el-option label="共享订单" value="1"></el-option>
            <el-option label="秒杀订单" value="3"></el-option>
            <el-option label="直播礼物" value="5"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            style="margin-left: 100px"
            type="primary"
            icon="el-icon-search"
            @click="search"
            >搜索</el-button
          >
          <el-button @click="resetForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- -----------------------表格区域 --------------------------------->
    <div class="content-table">
      <el-table
        :data="tableData"
        :header-cell-style="{ 'text-align': 'center' }"
        :cell-style="{ 'text-align': 'center' }"
        style="width: 100%"
        border
      >
        <el-table-column type="index" label="#"> </el-table-column>
        <el-table-column prop="serialNumber" label="订单编号" width="auto">
        </el-table-column>
        <el-table-column prop="userId" label="会员ID" width="auto">
        </el-table-column>
        <el-table-column prop="agreementRefund" label="退款金额" width="auto">
        </el-table-column>
        <el-table-column prop="actualRecund" label="实际退款金额" width="auto">
        </el-table-column>
        <el-table-column prop="phone" label="联系电话" width="auto">
        </el-table-column>
        <el-table-column prop="reason" label="退款原因" width="auto">
        </el-table-column>
        <el-table-column prop="createTime" label="退款时间" width="auto">
        </el-table-column>

        <el-table-column label="订单类型" min-width="120">
          <template slot-scope="scope">
            <span v-if="scope.row.wlStatus == 0">物流订单</span>
            <span v-else-if="scope.row.wlStatus == 1">自提订单</span>
          </template>
        </el-table-column>
        <el-table-column label="订单类别" min-width="120">
          <template slot-scope="scope">
            <span v-if="scope.row.isPubGoods == 0">普通订单</span>
            <span v-else-if="scope.row.isPubGoods == 1">共享订单</span>
            <span v-else-if="scope.row.isPubGoods == 3">秒杀订单</span>
            <span v-else-if="scope.row.isPubGoods == 5">直播礼物</span>
          </template>
        </el-table-column>
        <el-table-column prop="businessCheckState" label="状态" width="auto">
          <template slot-scope="scope">
            <span
              v-if="
                scope.row.businessCheckState === 0 &&
                scope.row.serviceCheckState === 0
              "
              >未审核
            </span>
            <span
              v-else-if="
                scope.row.businessCheckState === 1 ||
                scope.row.serviceCheckState === 1
              "
              >同意</span
            >
            <span
              v-else-if="
                scope.row.businessCheckState === 2 ||
                scope.row.serviceCheckState === 2
              "
              >不同意</span
            >
          </template>
        </el-table-column>
        <el-table-column label="操作" width="auto">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="
                toOrderDetails(
                  scope.row.id,
                  scope.row.businessCheckState,
                  scope.row
                )
              "
              >查看</el-button
            >
            <el-button
              v-if="
                scope.row.businessCheckState === 0 &&
                scope.row.serviceCheckState === 0
              "
              type="text"
              size="small"
              @click="audit(scope.row)"
              >审核</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-box">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-sizes="[5, 10, 20, 50]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog title="退款审核" :visible.sync="dialogVisible" width="30%">
      <div>
        <el-form
          ref="auditForm"
          :model="form"
          :rules="rules"
          :before-close="auditDialogClose"
        >
          <el-form-item label="商家审核意见" prop="idea" label-width="120px">
            <el-input
              type="text"
              placeholder="请输入商家审核意见"
              v-model="form.idea"
            />
          </el-form-item>
          <el-form-item label="审核结果" prop="status" label-width="120px">
            <el-select v-model="form.status" placeholder="请选择">
              <el-option label="同意" value="1"> </el-option>
              <el-option label="不同意" value="2"> </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="auditDialogClose">取 消</el-button>
        <el-button type="primary" @click="auditSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 侧边栏查看详情 -->
    <el-drawer title="查看详情" size="60%" :visible.sync="detailsDrawerVisible">
      <Details
        :orderData="orderDetailData"
        :isRefund="true"
        :refundType="refundType"
      ></Details>
    </el-drawer>
  </div>
</template>
<script>
import Details from "@/views/manage/order/Details.vue";
export default {
  data() {
    return {
      refundType: null, // 退款类型
      detailsDrawerVisible: null, // 查看详情
      orderDetailData: null, // 订单详情
      searchFrom: {
        phone: "", // 手机号
        serialNumber: "", // 订单号
        goodsName: "", // 商品名称
        refundState: null, // 退款状态
        wlStatus: null, // 物流状态
        isPubGoods: null, // 是否为共享商品
      }, // 搜索条件
      // 审核意见
      form: {
        idea: "", // 商家审核意见
        status: null, // 审核结果
        quantity: null, // 退款数量
      },
      dialogVisible: false, //审核意见
      // 校验规则
      rules: {
        idea: [
          { required: true, message: "请输入商家审核意见", trigger: "blur" },
        ],
        status: [
          { required: true, message: "请选择审核结果", trigger: "change" },
        ],
      },
      // 表格数据
      tableData: [],
      loading: false,
      // 条数
      pageSize: 10,
      // 当前页
      pageNum: 1,
      // 总条数
      total: 0,
      currentId: null,
    };
  },
  components: {
    Details,
  },
  created() {
    this.getOrderList();
  },
  methods: {
    toOrderDetails(id, refundType, row) {
      // 当前退款状态0退款成功，1退款失败，2退款中
      if (row.shippingState === 7) {
        this.refundType = 0; //退款成功
      } else {
        if (row.businessCheckState === 2 || row.serviceCheckState === 2) {
          this.refundType = 1; //退款失败
        } else {
          this.refundType = 2; //退款中
        }
      }
      const isSharedGoods = row.isPubGoods;
      // console.log(id);
      // 查看 详情
      this.$api.order
        .orderDetails({ id: id, isSharedGoods: isSharedGoods })
        .then((res) => {
          const { data, success, message } = res;
          // data
          if (!success) {
            this.$message.error(message);
            return;
          }
          this.orderDetailData = data;
          this.detailsDrawerVisible = true;
        });
    },
    resetForm() {
      this.searchFrom = {};
      this.getOrderList();
    },
    // 搜索
    search() {
      this.getOrderList();
    },
    // 弹窗显示 审核
    audit(row) {
      let id = row.id;
      this.currentId = row.id;
      this.form.quantity = row.quantitys;
      this.dialogVisible = true;
    },
    // 审核提交
    auditSubmit() {
      this.$refs["auditForm"].validate((valid) => {
        if (valid) {
          let resData = {
            id: this.currentId,
            result: this.form.status,
            msg: this.form.idea,
            quantity: this.form.quantity,
          };
          this.$api.order.auditRefundOrder(resData).then((res) => {
            const { data, success, message } = res;
            this.auditDialogClose();
            if (!success) {
              this.$message.error(message);
            } else {
              this.getOrderList();
            }
          });
        }
      });
    },
    auditDialogClose() {
      this.$refs["auditForm"].resetFields();
      // 弹窗关闭
      this.dialogVisible = false;
    },
    getOrderList() {
      let resData = {
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        refund: 1,
        ...this.searchFrom,
      };
      this.loading = true;
      this.$api.order.shopOrderList(resData).then((res) => {
        this.loading = false;
        if (!res.success) {
          this.tableData = [];
          this.total = 0;
          return;
        }
        this.tableData = res.data.list;
        this.total = parseInt(res.data.total);
      });
    },

    handleSizeChange(val) {
      this.pageSize = val;
      this.getOrderList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getOrderList();
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  .pagination-box {
    padding: 8px 0;
    display: flex;
    justify-content: flex-end;
  }
}
</style>
